<script>
  import { h } from 'vue';

  function replaceCharacterInPath(path) {
    path = path.replace("%c3%a4", "ae");
    path = path.replace("%c3%b6", "oe");
    path = path.replace("%c3%bc", "ue");
    path = path.replace("%20", "_");

    return path;
  }

  function createBreadcrumb(paths) {
    var breadcrumb_items = [h("li", { class: "breadcrumb-item" }, [<router-link to="/">home</router-link>])];
    var url = "";
    paths.splice(0,1);
    paths.forEach((path, key, paths) => {
      url += "/" + path;
      if (Object.is(paths.length - 1, key)) {
        breadcrumb_items.push(h("li", { class: "breadcrumb-item active", 'aria-current': "page" }, replaceCharacterInPath(path.toLowerCase())));
      } else {
        breadcrumb_items.push(h("li", { class: "breadcrumb-item" }, [<router-link to={url}>{replaceCharacterInPath(path.toLowerCase())}</router-link>]));
      }
    });
    return breadcrumb_items;
  }

  export default {
    render() {
      return h("nav", { class: "breadcrumb-nav", "aria-label": "breadcrumb" }, [
        h("ol", { class: "breadcrumb"}, [
          createBreadcrumb(this.$route.fullPath.split("/"))
        ])
      ]);
    }
  }
</script>