<template>
<div v-if=isLogin()>
    <Navigation />
    <Breadcrumb />
    <main>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-10">
              <div class="card">
                <div class="card-header">
                  <h1 class="h4 mt-2">Allgemeine Hinweise</h1>
                </div>
                <div class="card-body">
                  <p class="card-text">Die hier vorliegenden Therapiestandards, Dosierungen und Indikationen sind interne Empfehlungen, die anhand vorliegender aktueller Leitlinienempfehlungen der jeweiligen Fachgesellschaften und dem lokalen Resistenzspektrum erstellt werden. Durch regelmäßige unterjährige Reevalution durch das ABS-Team bleibt die Aktualität gewahrt. Die Empfehlungen besitzen einen Leitlinien-Charakter. Das bedeutet, dass von ihnen abgewichen werden kann, wenn Gründe dafür vorhanden sind.</p>
                  <p class="card-text">Im Zweifel bleiben immer die Angaben des Präparateherstellers, d.h. die Packungsbeilage und Fachinformation, maßgeblich.</p>
                  <p class="card-text">Verantwortlich für den Inhalt</p>
                  <p class="card-text">Firma A<br>
                  Straße<br>
                  Fon +49(0)123456789<br>
                  Fax +49(0)123789456<br>
                  E-Mail info@contilia.de</p>
                  <p class="card-text">Geschäftsführung<br>
                  Namen</p>
                  <p class="card-text">Registergericht<br>
                  Amtsgericht <br>
                  Umsatzsteuer-Identifikations-Nr.: XXXXX</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main> 
  </div>
  <div v-else>
    <Login />
  </div>
</template>

<script>
  // @ is an alias to /src
  import Navigation from "@/components/Navigation.vue";
  import Login from "@/components/Login.vue";
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import Mixin from "../mixin.js";

  export default {
    mixins: [Mixin],
    components: {
      Navigation,
      Login,
      Breadcrumb
    },
    created() {
      document.title = "Antibiotika App - Allgemeine Hinweise";
    },
  }
</script>