<template>
<div v-if=isLogin()>
    <Navigation />
    <Breadcrumb />
    <main>
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-10">
              <div class="card">
                <div class="card-header">
                  <h1 class="h4 mt-2">Dosierungsanpassungen</h1>
                </div>
                <div class="card-body">
                  <div class="box">
                    <div class="box-collapse">
                      <a class="box-collapse-toggle d-flex align-items-center collapsed" data-toggle="collapse" href="#collapseOne" role="button" aria-expanded="false" aria-controls="collapseOne">
                        Normalgewicht
                        <i class="fas fa-chevron-down ml-auto"></i>
                      </a>
                      <div class="collapse" id="collapseOne" style="">
                        <div class="box-body">
                          <p class="box-text">Dosierung bei Normalgewicht</p>
                          <p class="box-text">Allgemein werden absolute Dosierungen verwendet (z.B. 1 x 2 g i.v.)<br>
                          Bei auf das Körpergewicht bezogener Dosierung in mg/kgKG wird bei Erwachsenen mit dem „idealen Körpergewicht“ gerechnet:</p>
                          <p class="box-text">IBW = ideal bodyweight bei Frauen (45 kg)
                          + 1 kg pro cm Körpergröße über 150 cm</p>
                          <p class="box-text">IBW = ideal bodyweight bei Männern (50 kg)
                          + 1 kg pro cm Körpergröße über 150 cm</p>
                          <p class="box-text">Beispiel:<br>
                          Ein 180 cm großer und 90 kg schwerer Mann soll Gentamicin 5 mg/kg erhalten. Das Idealgewicht beträgt bei ihm also: IBW = 50 kg + (30 x 1 kg) = 80 kg.<br>
                          Somit beträgt die Dosierung: 5 mg x 80 = 400 mg Gentamicin.</p>
                        </div>
                      </div>
                    </div>
                    <div class="box-collapse">
                      <a class="box-collapse-toggle d-flex align-items-center collapsed" data-toggle="collapse" href="#collapseThree" role="button" aria-expanded="false" aria-controls="collapseThree">
                        Adipositas
                        <i class="fas fa-chevron-down ml-auto"></i>
                      </a>
                      <div class="collapse" id="collapseThree">
                        <div class="box-body">
                          <p class="box-text">Dosierung bei Adipositas (BMI &gt; 35)</p>
                          <p class="box-text">Bei absoluter Dosierung (z.B. 1 x 2 g i.v.) wird die Dosis der Einmalgabe erhöht (1,5 bis 2-fach)
                          Bei auf das Körpergewicht bezogener Dosierung in mg/kgKG geht man nach folgender Formel vor:<br>
                          Liegt das tatsächliche Körpergewicht (ABW = actualbodyweight) 30% über dem IBW, dann wird das adjustierte Dosierungsgewicht (ADW = adjusteddosignweight) ermittelt und zur Dosierung verwendet:</p>
                          <p class="box-text">ADW = IBW + 0,4 X (ABW – IBW)</p>
                          <p class="box-text">Beispiel:<br>
                          Ein 170 cm großer und 115 kg schwerer Mann hat ein IBW von: 50 + (20 x 1 kg) = 70 kg. Sein wirkliches Gewicht von 115 kg liegt aber um 45 kg darüber.<br>
                          Bei absoluter Dosierung (z.B. 1 x 2 g Cefotaximi.v.) gibt man jetzt das 1,5 bis 2-fache, also z.B. 3 oder 4 g Cefotaxim i.v.<br>
                          Bei einer Dosierung in mg/kg rechnet man das adjustierte Gewicht aus, also ADW = 70 kg + 0,4 x (115 kg – 70 kg), also 88 kg. Für Gentamicin 5 mg/kg würde der Patient also 5 x 88 kg = 440 mg erhalten.</p>
                        </div>
                      </div>
                    </div>
                    <div class="box-collapse">
                      <a class="box-collapse-toggle d-flex align-items-center collapsed" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false" aria-controls="collapseTwo">
                        Niereninsuffizienz
                        <i class="fas fa-chevron-down ml-auto"></i>
                      </a>
                      <div class="collapse" id="collapseTwo">
                        <div class="box-body">
                          <p class="box-text">Dosierung bei eingeschränkter Nierenfunktion</p>
                          <p class="box-text">Eine Dosisanpassung sollte in Betracht kommen bei Patienten
                          _ Über 60 Jahre
                          _ Unter 60 kg
                          _ Mit einem Kreatinin &gt; 1 mg/dl
                          Dann erfolgt die Berechnung der Kreatinin-Clearance nach folgender Formel:</p>
                          <p class="box-text">Kreatinin-Clearance = (140-Alter)/Serumkreatinin x kg/72 (x 0,85 bei ♀)</p>
                          <p class="box-text">Die Kreatinin-Clearance wird vereinfachend mit der glomerulären Filtrationsrate (GFR) gleichgesetzt. Bei eingeschränkter Nierenfunktion bzw. Niereninsuffizienz wird das Antibiotikum anhand der Fachinformation individuell angepasst und dosiert. Dies kann zu einer Senkung der Dosis, einer Senkung der Häufigkeit der Gabe oder einer Erhöhung des Dosierungsintervalls führen und ist für jedes Antibiotikum sowie patientenindividuell durchzuführen. Die in diesen Empfehlungen angegebenen Dosierungen gehen grundsätzlich von einer normalen Nierenfunktion aus.</p>
                          <p class="box-text">Siehe auch auf der Seite der Universität Heidelberg:
                          www.dosing.de</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
  <div v-else>
    <Login />
  </div>
</template>

<script>
  // @ is an alias to /src
  import Navigation from "@/components/Navigation.vue";
  import Login from "@/components/Login.vue";
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import Mixin from "../mixin.js";

  export default {
    mixins: [Mixin],
    components: {
      Navigation,
      Login,
      Breadcrumb
    },
    created() {
      document.title = "Antibiotika App - Dosierungsanpassungen";
    },
  }
</script>